import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import NodeServiceTeaser from 'src/components/NodeServiceTeaser/NodeServiceTeaser';

const order = {
    'project-planning': 0,
    'quality-assurance-and-system-integration': 5,
    'application-development': 2,
    'augmented-reality-ar-mr': 4,
    'virtual-reality': 3,
    'technology-research': 1
}

function ViewsNodeServiceTeasers() {
  const data = useStaticQuery(graphql`
    {
      allSanityService {
        nodes {
          id
          title
          slug {
            current
          }
          serviceBodySections {
            title
          }
          description
          longTitle
          previewImage {
            alt
            image {
              asset {
                gatsbyImageData(layout: FULL_WIDTH, width: 309, height: 276, placeholder: BLURRED)
              }
            }
          }
        }
      }
    }
  `);

  const {
    allSanityService: { nodes: services },
  } = data;

  return (
    <Container className="my-6">
      <Row id="service-teasers">
        {services.map(service => ({...service, order: order[service.slug.current]})).sort((a, b) => a.order - b.order).map((service, i) => (
          <Col key={service.id} xs={12} className="py-6">
            <NodeServiceTeaser service={service} index={i + 1} />
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ViewsNodeServiceTeasers;
